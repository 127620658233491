.coverCont {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../img/login/imgBg_login.png');
}

.coverCont .UI {
  width: 25vw;
  height: calc(25vw / 1.5498);

  background-image: url('../img/login/firstImg.png');
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  margin: auto auto 10px auto;
}

.coverCont h1 {
  margin: 10px auto auto auto;
  color: #FFF;
  width: 90%;
  font-size: 3vw;
  text-align: center;
  font-family: SofiaPro-semiBold;
}

@media (max-width: 768px) {
  .coverCont .UI {
    width: 55vw;
    height: calc(55vw / 1.5498);
  }

  .coverCont h1 {
    font-size: 6vw;
  }
}