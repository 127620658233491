.formContainer {
  width: 70%;

  display: flex;

  margin: auto;
}

.formContainer .form {
  position: relative;

  width: 100%;

  display: flex;
  flex-flow: column;
}

.formContainer .loanding {
  width: 20vw;
  height: 20vw;

  margin: auto;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../../img/login/loanding.png');

  z-index: 10;

  animation: rotate infinite 1.5s linear;
  animation-play-state: running;
  -moz-animation-play-state:running;
}
.formContainer .loanding:hover {
  animation: rotate infinite 1s linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.formContainer .form input {
  width: 55.5%;

  margin: auto;
  padding: 10px 3vw;

  border-radius: 200px;
  border: none;
  outline: none;

  text-align: start;
  font-size: 1.4vw;
  color: #111;
  font-weight: 300;
  font-family: Sancoale;

  z-index: 10;
}

.formContainer .form input::placeholder {
  color: #999;
}

/* ======================================= */

.formContainer .form .userName::before {
  position: absolute;
  top: -10%;
  left: 17%;

  content: "";
  display:block;

  width: 64%;
  height: 95%;

  background-color: #1CF4E9;
  border-radius: 200px;
}

.formContainer .form .userName::after {
  position: absolute;
  top: 15%;
  left: 19%;

  content: "";
  display:block;

  width: 64%;
  height: 95%;

  background-color: #F12A51;
  border-radius: 200px;
}

/* ------------------------- */

.formContainer .form .ccmsID::before {
  position: absolute;
  top: -10%;
  left: 17%;

  content: "";
  display:block;

  width: 64%;
  height: 95%;

  background-color: #1CF4E9;
  border-radius: 200px;
}

.formContainer .form .ccmsID::after {
  position: absolute;
  top: 15%;
  left: 19%;

  content: "";
  display:block;

  width: 64%;
  height: 95%;

  background-color: #F12A51;
  border-radius: 200px;
}

/* ------------------------- */

.formContainer .form .userEmail::before {
  position: absolute;
  top: -10%;
  left: 17%;

  content: "";
  display:block;

  width: 64%;
  height: 95%;

  background-color: #1CF4E9;
  border-radius: 200px;
}

.formContainer .form .userEmail::after {
  position: absolute;
  top: 15%;
  left: 19%;

  content: "";
  display:block;

  width: 64%;
  height: 95%;

  background-color: #F12A51;
  border-radius: 200px;
}

/* ======================================= */

.formContainer .form .btnSubmit {
  background-color: #F12A51;
  border: none;
  outline: none;
  border-radius: 10px;

  color: #FFF;
  text-shadow: 0px 0px 7px #FFF;
  font-size: 1.7vw;
  font-weight: 100;
  font-family: SofiaPro-semiBold;

  padding: 10px 30px;
  margin: 20px auto;

  cursor: pointer;
}

/* ============================================= */
/* ============================================= */

.formContainer .errorMsg {
  width: 60%;

  margin: auto;
  padding: 20px;

  background-color: #F12A51;
  border-radius: 20px;

  display: flex;
  flex-flow: column;
}

.formContainer .errorMsg h2 {
  margin: auto;

  text-align: center;
  font-size: 3vw;
  text-transform: uppercase;
  color: #FFF;
  text-shadow: 0px 0px 5px #FFF;
}

.formContainer .errorMsg .back {
  padding: 10px 30px;
  margin: 20px auto;

  background-color: #FFF;
  border-radius: 10px;
  border: none;
  outline: none;

  color: #F12A51;
  font-weight: 600;
  font-size: 2vw;

  cursor: pointer;
}

@media (max-width: 1024px) {
  .formContainer {
    width: 100%;
  }

  .formContainer .form input {
    width: 55.5%;
    padding: 10px 3vw;
    font-size: 2.6vw;
  }

  .formContainer .form .btnSubmit {
    font-size: 2.7vw;
  }
}

@media (max-width: 640px) {
  .formContainer .form input {
    width: 55.5%;
    padding: 10px 3vw;
    font-size: 3vw;
  }

  .formContainer .form .btnSubmit {
    font-size: 4vw;
  }
}