/* Sancoale FONTS */

@font-face {
  font-family: Sancoale-light;
  src: url('./Sancoale\ Softened\ Light.woff');
}

@font-face {
  font-family: Sancoale;
  src: url('./Sancoale\ Softened.woff');
}


/* Sofia Pro FONTS */

@font-face {
  font-family: SofiaPro-Bold;
  src: url('./Sofia\ Pro\ Bold\ Az.woff');
}

@font-face {
  font-family: SofiaPro-light;
  src: url('./Sofia\ Pro\ Light\ Az.woff');
}

@font-face {
  font-family: SofiaPro-regular;
  src: url('./Sofia\ Pro\ Regular\ Az.woff');
}

@font-face {
  font-family: SofiaPro-semiBold;
  src: url('./Sofia\ Pro\ Semi\ Bold\ Az.woff');
}