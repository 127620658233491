.loginContainer {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/login/imgBg_login.png');
}

.loginContainer .soporte {
  position: absolute;
  bottom: 2vw;
  right: 2vw;

  margin: auto;
  padding: 10px 20px;

  text-transform: uppercase;
  text-decoration: none;
  color: #FFF;
  font-family: SofiaPro-semiBold;
  font-weight: 600;

  background-color: #F12A51;
  border-radius: 10px;
}

.loginContainer .soporte::before {
  content: "Soporte | Help";
}

.loginContainer .imgLogo {
  position: absolute;
  top: 1.5vw;
  right: 1.5vw;

  width: 3vw;
  height: 3vw;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/login/TeleLogo.svg');
}

.loginContainer .UI {
  width: 25vw;
  height: calc(25vw / 1.5498);

  background-image: url('../../img/login/firstImg.png');
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  margin: auto auto 10px auto;
}

.loginContainer .forms {
  width: 100%;

  margin:  10px auto auto auto;

  display: flex;
  flex-flow: column;
}

/* ============================================= */
/* ============================================= */
/* ============================================= */

@media (max-width: 1024px) {
  .loginContainer .imgLogo {
    position: absolute;
    top: 3vw;
    right: 3vw;

    width: 8vw;
    height: 8vw;
  }

  .loginContainer .UI {
    width: 60vw;
    height: calc(60vw / 1.5498);
  }

  .loginContainer .soporte {

    font-size: 1.5vw;

    /* background-position: center;
    background-image: url('../../img/login/help.png');
    background-size: 80%;
    background-repeat: no-repeat; */
  }

  .loginContainer .soporte::before {
    content: "Soporte";
  }
}

@media (max-width: 640px) {
  .loginContainer .imgLogo {
    position: absolute;
    top: 4vw;
    right: 4vw;

    width: 10vw;
    height: 10vw;
  }

  .loginContainer .UI {
    width: 85vw;
    height: calc(85vw / 1.5498);
  }

  .loginContainer .soporte {

    font-size: 2.5vw;
  }
}