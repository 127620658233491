.ChatContainer {
  width: 80%;
  height: 78%;

  margin: 14% auto auto auto;

  display: flex;
  flex-flow: column;
}

.ChatContainer .chat {
  position: relative;

  width: 100%;
  height: 100%;

  margin: auto;
  padding: 1vw 0px;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: scroll;

  scrollbar-width: thin;
  scrollbar-color: #F12A51 #FFF;
}

/* width and height */
.ChatContainer .chat::-webkit-scrollbar {
  width: 10px; height: 6px;
}

/* Track */
.ChatContainer .chat::-webkit-scrollbar-track {
  background: #FFF;
  border-radius: 10px;
}

/* Handle */
.ChatContainer .chat::-webkit-scrollbar-thumb {
  background: #F12A51;
  border-radius: 10px;
}

/* Handle on hover */
.ChatContainer .chat::-webkit-scrollbar-thumb:hover {
  background: #F12A51;
}

.ChatContainer .chat .chatBuffer {
  width: 90%;

  margin: auto;
  padding: 10px;

  border-radius: 20px;

  background-color: #FFF;
}

.ChatContainer .chat .chatBuffer .chatCard {
  width: 80%;
  padding: 10px;
  background-color: #FFF;
  border-radius: 10px 10px 10px 0px;
  box-shadow: 0px 0px 10px #AAA;
  margin: auto auto 1vh 0%;
  text-align: start;

  color: #F12A51;
  font-weight: 600;
  font-size: 1vw;
  font-family: SofiaPro-light;

  overflow: auto;
}

.ChatContainer .chat .chatBuffer .chatCard .chatUsername {
  margin: 0px;
  font-weight: 300;
  font-size: .9vw;
  text-align: start;
  font-family: SofiaPro-semiBold;

  border-bottom: 1px solid #F12A51;
}

.ChatContainer .chat .chatBuffer .chatOwnCard {
  width: 85%;

  margin: auto 0% 1vw auto;
  padding: 10px;

  border: none;
  border-radius: 10px 10px 0px 10px;

  background-color: #F12A51;
  color: #FFF;
  font-size: 1vw;
  text-align: end;
  font-family: SofiaPro-light;

  overflow: auto;
}

.ChatContainer .chat .chatBuffer .chatOwnCard .chatUsername {
  margin: auto;
  font-weight: 300;
  font-size: .9vw;
  text-align: start;
  font-family: SofiaPro-semiBold;

  border-bottom: 1px solid #FFF;
}

.ChatContainer .chat .chatBuffer .chatCard .chatMessage {
  margin: 10px auto 5px auto;
  font-weight: 300;
  font-size: .9vw;
  text-align: start;
}

.ChatContainer .chat .chatBuffer .chatOwnCard .chatMessage {
  margin: 10px auto 5px auto;
  font-weight: 300;
  font-size: .9vw;
  text-align: end;
}

/* width and height */
.ChatContainer .chat .chatBuffer *::-webkit-scrollbar {
  width: 10px; height: 6px;
}

/* Track */
.ChatContainer .chat .chatBuffer *::-webkit-scrollbar-track {
  background: #FFF;
  border-radius: 10px;
}

/* Handle */
.ChatContainer .chat .chatBuffer *::-webkit-scrollbar-thumb {
  background: #F12A51;
  border-radius: 10px;
}

/* Handle on hover */
.ChatContainer .chat .chatBuffer *::-webkit-scrollbar-thumb:hover {
  background: #7a182c;
}

/* =============================================== */

.ChatContainer .chatForm {
  position: relative;

  width: 90%;

  margin: 20px auto 0% auto;

  display: flex;

  z-index: 100;
}

.ChatContainer .chatForm .chatInput {
  width: 95%;

  margin: auto;
  padding: 10px 20px;
  padding-right: 3vw;

  border-radius: 200px;
  border: none;
  outline: none;

  text-align: start;
  font-size: 1vw;
  color: #111;
  font-family: SofiaPro-regular;

  z-index: 10;
}

.ChatContainer .chatForm .chatInput::placeholder {
  color: #999;
  font-family: SofiaPro-semiBold;
}

.ChatContainer .chatForm .azul {
  position: absolute;
  top: -15%;
  left: -3%;

  content: "";
  display:block;

  width: 100%;
  height: 100%;

  background-color: #1CF4E9;
  border-radius: 200px;

  z-index: 10;
}

.ChatContainer .chatForm .rojo {
  position: absolute;
  top: 15%;
  left: 3%;

  content: "";
  display:block;

  width: 100%;
  height: 100%;

  background-color: #F12A51;
  border-radius: 200px;

  z-index: 10;
}

.ChatContainer .chatForm .chatInputButton {
  position: absolute;
  right: 2%;
  top: 10%;

  z-index: 10;

  width: 10%;
  height: 80%;

  border-radius: 100px;
  border: none;

  color: #FFF;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #F12A51;

  cursor: pointer;
}

.ChatContainer .chatForm .chatInputButton svg {
  width: 100%;
  height: 100%;
}



/* ========================================== */
/* ========================================== */
/* ========================================== */
/* ========================================== */


@media (max-width: 1300px) {
  .ChatContainer .chatForm .chatInputButton {
    width: 13%;
  }

  .ChatContainer .chat .chatBuffer .chatCard .chatMessage {
    font-size: 1.2vw;
  }

  .ChatContainer .chat .chatBuffer .chatOwnCard .chatMessage {
    font-size: 1.2vw;
  }

  .ChatContainer .chat .chatBuffer .chatCard .chatUsername {
    font-size: 1.2vw;
  }
  .ChatContainer .chat .chatBuffer .chatOwnCard .chatUsername {
    font-size: 1.2vw;
  }
}

@media (max-width: 1024px) {

  .ChatContainer .chat .chatBuffer .chatCard {
    font-size: 2.3vw;
  }

  .ChatContainer .chat .chatBuffer .chatCard .chatMessage {
    font-size: 2vw;
  }

  .ChatContainer .chat .chatBuffer .chatOwnCard .chatMessage {
    font-size: 2vw;
  }

  .ChatContainer .chat .chatBuffer .chatCard .chatUsername {
    font-size: 2vw;
  }
  .ChatContainer .chat .chatBuffer .chatOwnCard .chatUsername {
    font-size: 2vw;
  }

  .ChatContainer .chatForm .chatInput {
    text-align: start;
    font-size: 2vw;
    color: #F12A51;
  }

  .ChatContainer .chatForm .chatInputButton {
    width: 6%;
  }
}

@media (max-width: 768px) {
  .ChatContainer .chatForm .chatInputButton {
    width: 8%;
  }
}

@media (max-width: 640px) {
  .ChatContainer .chat .chatBuffer .chatCard {
    font-size: 3.3vw;
  }

  .ChatContainer .chat .chatBuffer .chatCard .chatMessage {
    font-size: 3vw;
  }

  .ChatContainer .chat .chatBuffer .chatOwnCard .chatMessage {
    font-size: 3vw;
  }

  .ChatContainer .chat .chatBuffer .chatCard .chatUsername {
    font-size: 3vw;
  }
  .ChatContainer .chat .chatBuffer .chatOwnCard .chatUsername {
    font-size: 3vw;
  }

  .ChatContainer .chatForm .chatInput {
    text-align: start;
    font-size: 3.2vw;
    color: #F12A51;
  }

  .ChatContainer .chatForm .chatInputButton {
    width: 12%;
  }
}