input:-webkit-autofill {
  border: 1px solid #65181a;
  -webkit-text-fill-color: #65181a;
  -webkit-box-shadow: 0 0 0px 10000px #FAFAFA inset;
  box-shadow: 0 0 0px 10000px #ff7c80 inset;
}

.inputContainer {
  position: relative;
  width: 100%;

  margin: 20px auto;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.inputContainer p.warning {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  line-height: 1.5em;
  margin: auto;
  font-weight: 600;
  z-index: 1000;
}
.inputContainer p.light {
  color: #FF3333;
}
.inputContainer p.dark {
  color: #FFCCCC;
}

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}