div[class^="triviaUser"] {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  margin: auto;

  display: flex;

  background-color: rgba(0, 0, 0, 0.5);
  color: #FFF;
  font-family: SofiaPro-regular;

  z-index: 1000;
}

.triviaUser1 {
  justify-content: center;
  align-items: center;
}

.triviaUser1 .triviaCont {
  width: 50%;

  margin: auto auto 2% auto;

  display: flex;
  flex-flow: column;

  background-color: rgba(40, 40, 40, .7);
  backdrop-filter: blur(9px);
  border-radius: 30px;
}

.triviaUser1 h1 {
  line-height: 2.5vw;
  font-size: 2.5vw;
  margin: 50px auto;
  text-align: center;
  color: #F12A51;
}

.triviaUser2 {
  flex-flow: column;
}

.triviaUser2 .triviaCont {
  width: 50%;

  margin: auto auto 2% auto;

  display: flex;
  flex-flow: column;

  background-color: transparent;
  background-color: rgba(40, 40, 40, .7);
  backdrop-filter: blur(9px);
  border-radius: 30px;
}

.triviaUser2 .triviaTimeAndQuestion {
  width: 90%;
  margin: 10px auto;
  display: flex;
  flex-flow: column;

  border-bottom: 2px solid #F12A51;
}

.triviaUser2 .triviaTimeAndQuestion h3 {
  font-size: 4vw;
  line-height: 4.5vw;
  font-weight: 100;
  margin: 0;

  margin: auto;

  color: #1CF4E9;
  text-shadow: 0px 0px 10px #1CF4E9;
}

.triviaUser2 .triviaTimeAndQuestion h2 {
  font-size: 2.5vw;
  line-height: 2.5vw;
  margin: 0;

  margin: 10px auto;

  color: #FFC7D2;
  text-shadow: 0px 0px 10px #F12A51;
  text-align: center;
}

/* ====================================================== */

.triviaUser2 .triviaAnswers {
  width: 100%;

  margin: 10px auto;

  display: flex;
  flex-flow: column;
}

.triviaUser2 .triviaAnswers h2 {
  line-height: 2.5vw;
  font-size: 2.5vw;
  font-weight: 200;

  margin: 0px auto 10px auto;

  color: #97fffa;
  text-shadow: 0px 0px 5px #1CF4E9;
}

.triviaUser2 .triviaAnswers .answersCont {
  width: 90%;

  margin: auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.triviaUser2 .triviaAnswers .answersCont .triviaAnswer {
  margin: 5px;
  padding: 2% 5%;

  border: 0;
  border-radius: 5px;

  cursor: pointer;

  min-width: 10%;
  background-color: #F12A51;
  font-weight: 900;
  color: #FFF;

  transition: .15s;
}

.triviaUser2 .triviaAnswers .triviaAnswer:hover {
  transform: scale(.97);
}

/* ================================================== */

.triviaUser5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.triviaUser5 .triviaCont {
  width: 50%;

  margin: auto auto 2% auto;

  display: flex;
  flex-flow: column;

  background-color: transparent;
  background-color: rgba(40, 40, 40, .9);
  backdrop-filter: blur(3px);
  border-radius: 30px;
}

.triviaUser5 .triviaCont h2 {
  line-height: 3.5vw;
  font-size: 3.5vw;
  font-weight: 200;

  margin: 10px auto;

  color: #97fffa;
  text-shadow: 0px 0px 5px #1CF4E9;
}

.triviaUser5 .triviaCont .winner {
  width: 70%;

  margin: 10px auto;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: SofiaPro-light;
  font-weight: 300;

  border-top: 1px solid #FFF;
}

.triviaUser5 .triviaCont .winner h3 {
  margin: 10px ;
}

/* ==================================================== */

.triviaUser6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.triviaUser6 .triviaCont {
  width: 30%;

  margin: auto auto 1% auto;
  padding: 2vw;

  display: flex;
  flex-flow: column;

  background-color: transparent;
  background-color: rgba(40, 40, 40, .9);
  backdrop-filter: blur(3px);
  border-radius: 30px;
}

.triviaUser6 .triviaCont h1 {
  line-height: 2.5vw;
  font-size: 2.5vw;
  margin: 2vw auto;
  text-align: center;
  color: #97fffa;
}

.triviaUser6 .triviaCont .optionsCont {
  width: 100%;

  display: flex;
  flex-flow: column;
}

.triviaUser6 .triviaCont .optionsCont .option {
  width: 90%;

  margin: 5px auto;
  padding: 5px 0px;

  border-bottom: 1px solid #97fffa;

  display: flex;
  align-items: center;
  justify-content: center;
}

.triviaUser6 .triviaCont .optionsCont .option h2 {
  margin: 0px 10px;
  font-size: 1.5vw;
  color: #F12A51;
}

.triviaUser6 .triviaCont .optionsCont .option p {
  margin: 0px 10px;
  color: #1CF4E9;
  font-size: 1.5vw;
}

/* ==================================================== */

@media (max-width: 1024px) {
  .triviaUser6 .triviaCont {
    width: 50%;
  }
}


@media (max-width: 800px) {

  .triviaUser5 .triviaCont {
    width: 75%;
    border-radius: 10vw;
    margin: auto;
  }

  .triviaUser2 {
    flex-direction: column;
  }

  .triviaUser2 .triviaCont {
    margin: auto;
    width: 80%;
  }

  .triviaUser2 .triviaTimeAndQuestion h2 {
    font-size: 3.2vw;
    line-height: 3.2vw;

    margin: 10px auto;
  }

  .triviaUser1 .triviaCont {
    width: 80%;
    margin: auto;
  }

  .triviaUser1 h1 {
    line-height: 4.5vw;
    font-size: 4.5vw;
  }

  .triviaUser6 .triviaCont {
    width: 90%;
    margin: auto;
  }

  .triviaUser6 .triviaCont h1 {
    line-height: 3.5vw;
    font-size: 3.5vw;
  }
  .triviaUser6 .triviaCont .optionsCont .option h2 {
    font-size: 2.5vw;
  }

  .triviaUser6 .triviaCont .optionsCont .option p {
    font-size: 2.5vw;
  }
}

@media (max-width: 640px) {
  .triviaUser6 .triviaCont h1 {
    line-height: 4vw;
    font-size: 4vw;
  }
  .triviaUser6 .triviaCont .optionsCont .option h2 {
    font-size: 3vw;
  }

  .triviaUser6 .triviaCont .optionsCont .option p {
    font-size: 3vw;
  }
}
