.roomContainer {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/login/imgBg_login.png');
}


.roomContainer .imgLogo {
  position: absolute;
  top: 1.2vw;
  left: 1.2vw;

  width: 3vw;
  height: 3vw;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/login/TeleLogo.svg');

  z-index: 10;
}

.roomContainer .logOut {
  position: absolute;
  top: 1.2vw;
  right: 1.2vw;

  border: none;
  outline: none;
  border-radius: 10px;

  padding: 10px 20px;

  background-color: #F12A51;
  color: #FFF;
  font-weight: 600;
  text-transform: uppercase;
  font-family: SofiaPro-semiBold;

  z-index: 10;

  cursor: pointer;
}

.roomContainer .sections {
  width: 100vw;
  height: 100vh;

  margin: auto;

  display: flex;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #F12A51 transparent;
}

/* width and height */
.roomContainer .sections::-webkit-scrollbar {
  width: 6px; height: 6px;
}

/* Track */
.roomContainer .sections::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
.roomContainer .sections::-webkit-scrollbar-thumb {
  background: #F12A51;
  border-radius: 10px;
}

/* Handle on hover */
.roomContainer .sections::-webkit-scrollbar-thumb:hover {
  background: #7a182c;
}

.roomContainer .sections .stream {
  position: relative;

  width: 65vw;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.roomContainer .sections .stream .zoomBtn {
  position: absolute;
  top: auto;
  bottom: -10%;
  left: 5%;

  margin: auto;

  z-index: 1000;

  text-decoration: none;

  display: flex;
  align-items: center;
}

.roomContainer .sections .stream .imgZoom {
  width: 3vw;
  height: 3vw;

  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url('../../img/room/zoomBtn.png');

  z-index: 10;
}

.roomContainer .sections .stream .zoomBtn p {
  position: relative;
  left: -1vw;

  margin: auto 0px;
  padding: .7vw 1.4vw;

  background-color: #2D8CFF;
  border-radius: 10px;

  font-size: 1.1vw;
  color: #FFF;
}

.roomContainer .sections .stream .onAir {
  width: 7vw;
  height: calc(7vw / 2.7482);

  margin: 0vw auto -.5vw 3vw;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/room/OnAir.png');
}

.roomContainer .sections .stream .titleHide {
  position: relative;

  width: 50vw;
  height: calc(50vw / 1.5498);

  margin: auto;

  background-image: url('../../img/login/firstImg.png');
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  display: none;
}

.roomContainer .sections .stream .player {
  width: 58vw;
  height: calc(58vw / 1.7777);

  margin: 1vw auto auto auto;

  border: 4px solid #1CF4E9;
  border-radius: 30px;
  box-shadow: 0px 0px 20px #1CF4E9;
  -webkit-box-shadow: 0px 0px 20px #1CF4E9;
  -moz-box-shadow: 0px 0px 20px #1CF4E9;

  display: flex;

  overflow: hidden;
}

.roomContainer .sections .stream .player .iframeCont {
  width: 58vw;
  height: calc(58vw / 1.7777);

  margin: auto;

  display: flex;

  overflow: hidden;
}

.roomContainer .sections .stream .player iframe {
  width: 99.5%;
  height: 99.8%;

  margin: auto;

  border: none;
  outline: none;
  border-radius: 25px;
}

/* =================================================== */

.roomContainer .sections .interactions {
  position: relative;

  width: 30vw;
  height: calc(55vw / 1.7777);
  min-width: 300px;

  margin: calc((90vh - (58vw / 1.77777)) / 1.2) auto auto auto; /* calc(11vw / 2.7482) auto auto */

  display: flex;
  flex-flow: column;

  border: none;
}

.roomContainer .sections .interactions .corner {
  position: absolute;
}

.roomContainer .sections .interactions .top-left {
  top: 0;
  left: 0;

  width: 20%;
  height: 10%;

  border: none;
  border-radius: 30px 0px 0px 0px;
  border-left: 4px solid #FFC7D2;
  border-top: 4px solid #FFC7D2;

  box-shadow: -5px -5px 6px #F12A51AA,
              inset 5px 5px 6px #F12A51AA;

  -webkit-box-shadow: -5px -5px 6px #F12A51AA,
              inset 5px 5px 6px #F12A51AA;

  -moz-box-shadow: -5px -5px 6px #F12A51AA,
              inset 5px 5px 6px #F12A51AA;

  z-index: 10;
}

.roomContainer .sections .interactions .top-right {
  top: 0;
  right: 0;

  width: 20%;
  height: 10%;

  border: none;
  border-radius: 0px 30px 0px 0px;
  border-right: 4px solid #FFC7D2;
  border-top: 4px solid #FFC7D2;

  box-shadow: 5px -5px 6px #F12A51AA,
              inset -5px 5px 6px #F12A51AA;

  -webkit-box-shadow: 5px -5px 6px #F12A51AA,
              inset -5px 5px 6px #F12A51AA;

  -moz-box-shadow: 5px -5px 6px #F12A51AA,
              inset -5px 5px 6px #F12A51AA;

  z-index: 10;
}


.roomContainer .sections .interactions .right {
  top: 10.5%;
  right: 0;

  width: 1.5%;
  height: 79%;

  border: none;
  border-radius: 0px 0px 0px 0px;
  border-right: 4px solid #FFC7D2;

  box-shadow: 5px -5px 6px #F12A51AA,
              inset -5px 0px 6px #F12A51AA;

  -webkit-box-shadow: 5px -5px 6px #F12A51AA,
              inset -5px 0px 6px #F12A51AA;

  -moz-box-shadow: 5px -5px 6px #F12A51AA,
              inset -5px 0px 6px #F12A51AA;

  z-index: 5;
}

.roomContainer .sections .interactions .left {
  top: 10.5%;
  left: 0;

  width: 1.5%;
  height: 79%;

  border: none;
  border-radius: 0px 0px 0px 0px;
  border-left: 4px solid #FFC7D2;

  box-shadow: -5px -5px 6px #F12A51AA,
              inset 5px 0px 6px #F12A51AA;

  -webkit-box-shadow: -5px -5px 6px #F12A51AA,
              inset 5px 0px 6px #F12A51AA;

  -moz-box-shadow: 5px -5px 6px #F12A51AA,
              inset -5px 0px 6px #F12A51AA;

  z-index: 5;
}

.roomContainer .sections .interactions .bottom-left {
  bottom: 0;
  left: 0;

  width: 49.5%;
  height: 10%;

  border: none;
  border-radius: 0px 0px 0px 30px;
  border-left: 4px solid #FFC7D2;
  border-bottom: 4px solid #FFC7D2;

  box-shadow: -5px 5px 6px #F12A51AA,
              inset 5px -5px 6px #F12A51AA;

  -webkit-box-shadow: -5px 5px 6px #F12A51AA,
              inset 5px -5px 6px #F12A51AA;

  -moz-box-shadow: -5px -5px 6px #F12A51AA,
              inset 5px 5px 6px #F12A51AA;

  z-index: 4;
}

.roomContainer .sections .interactions .bottom-right {
  bottom: 0;
  right: 0;

  width: 49.5%;
  height: 10%;

  border: none;
  border-radius: 0px 0px 30px 0px;
  border-right: 4px solid #FFC7D2;
  border-bottom: 4px solid #FFC7D2;

  box-shadow: 5px 5px 6px #F12A51AA,
              inset -5px -5px 6px #F12A51AA;

  -webkit-box-shadow: 5px 5px 6px #F12A51AA,
              inset -5px -5px 6px #F12A51AA;

  -moz-box-shadow: 5px 5px 6px #F12A51AA,
              inset -5px -5px 6px #F12A51AA;

  z-index: 4;
}

/* ============================================= */

.roomContainer .sections .interactions .title {
  position: absolute;
  top: calc(-8vw / 1.5498);
  left: 25%;

  width: 15vw;
  height: calc(15vw / 1.5498);

  background-image: url('../../img/login/firstImg.png');
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}



/* ============================================= */
/* ============================================= */
/* ============================================= */

@media (max-width: 1024px) {

  .roomContainer .imgLogo {
    width: 6vw;
    height: 6vw;
  }

  .roomContainer .sections {
    display: flex;
    flex-flow: column;
    margin: 30px auto auto auto;
    height: 95vh;
  }

  .roomContainer .sections .stream {
    position: relative;

    width: 95vw;
  }

  .roomContainer .sections .stream .zoomBtn {
    bottom: -13%;
    left: 3.5%;
  }

  .roomContainer .sections .stream .imgZoom {
    width: 8vw;
    height: 8vw;
  }

  .roomContainer .sections .stream .zoomBtn p {
    position: relative;
    left: -3vw;

    margin: auto 0px;
    padding: 1vw 4.5vw;

    font-size: 4vw;
  }

  .roomContainer .sections .stream .onAir {
    position: absolute;
    top: 26%;
    left: 0;

    width: 15vw;
    height: calc(15vw / 2.7482);

    margin: 0vw auto 0vw 1.5vw;
  }

  .roomContainer .sections .stream .titleHide {
    width: 40vw;
    height: calc(40vw / 1.5498);

    display: block;
  }

  .roomContainer .sections .stream .player {
    width: 90vw;
    height: calc(90vw / 1.7777);
  }

  .roomContainer .sections .stream .player .iframeCont {
    width: 90vw;
    height: calc(90vw / 1.7777);
  }

  /* ------------------------------------------------ */

  .roomContainer .sections .interactions {
    width: 60vw;
    min-height: 460px;
    min-width: 250px;

    margin: calc(25vw / 0.8651) auto 50px auto;
  }


  .roomContainer .sections .interactions .title {
    position: absolute;
    top: calc(-10vw / 0.8651);
    left: 36%;

    width: 20vw;
    height: calc(20vw / 0.8651);

    background-image: url('../../img/room/rayitos.png');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

@media (max-width: 768px) {
  .roomContainer .sections .interactions {
    width: 80vw;
    min-height: 460px;
    min-width: 250px;

    margin: calc(20vw / 0.8651) auto 50px auto;
  }
}

@media (max-width: 640px) {
  .roomContainer .imgLogo {
    top: 4vw;
    left: 4vw;

    width: 8vw;
    height: 8vw;
  }
}