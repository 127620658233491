.checkBoxContainer {
  position: relative;

  width: 60%;
  margin: 10px auto;
  padding: 10px;

  display: flex;
  flex-flow: row;
}

.checkBoxContainer .textCont {
  font-size: 1.1vw;
  font-family: Sancoale;
  font-weight: 300;
  text-decoration: none;
  color: #FFF;
  text-align: start;
}

.checkBoxContainer .checkBox {
  display: none;
}

.checkBoxContainer .customCheckBox {
  position: relative;

  cursor: pointer;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 10;
}

.checkBoxContainer .customCheckBox .checkCont {
  position: relative;
  z-index: 10;
  margin: auto 20px;

  width: 33px;
  min-width: 33px;
  height: 33px;

  display: flex;
}

.checkBoxContainer .customCheckBox .azul {
  position: absolute;
  top: 0%;
  left: 0%;

  height: 25px;
  min-width: 25px;
  width: 25px;

  background-color: #1CF4E9;

  border-radius: 3px;

  z-index: -1;
}

.checkBoxContainer .customCheckBox .rojo {
  position: absolute;
  bottom: 0%;
  right: 0%;

  height: 25px;
  min-width: 25px;
  width: 25px;
  border-radius: 3px;

  background-color: #F12A51;

  z-index: -1;
}

.checkBoxContainer .customCheckBox .check {
  width: 25px;
  min-width: 25px;
  height: 25px;

  background-color: #FFF;
  border-radius: 3px;

  color: #AAA;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.checkBoxContainer .customCheckBox .ok {
  color: #000;
}

/* ---------------------------- */

.checkBoxContainer .ModalCont {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.6);

  display: flex;
  flex-flow: column;

  z-index: 1000;
}

.checkBoxContainer .ModalCont .modalstyle {
  position: relative;

  width: 55%;
  height: 75%;

  margin: auto;

  display: flex;
}

.checkBoxContainer .ModalCont .modalInner {
  width: 90%;
  height: 90%;

  background-color: #FFF;
  border-radius: 20px;

  margin: auto;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #F12A51 transparent;

  z-index: 1010;
}

/* width and height */
.checkBoxContainer .ModalCont .modalInner::-webkit-scrollbar {
  width: 6px; height: 6px;
}

/* Track */
.checkBoxContainer .ModalCont .modalInner::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
.checkBoxContainer .ModalCont .modalInner::-webkit-scrollbar-thumb {
  background: #F12A51;
  border-radius: 10px;
}

/* Handle on hover */
.checkBoxContainer .ModalCont .modalInner::-webkit-scrollbar-thumb:hover {
  background: #7a182c;
}

/* ----------------------------------------------- */

.checkBoxContainer .ModalCont .close {
  position: absolute;
  top: 14%;
  right: 23%;

  width: 25px;
  height: 25px;
  min-width: 25px;

  background-color: #F12A51;
  border-radius: 200px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #FFF;

  cursor: pointer;

  z-index: 100;
}

.checkBoxContainer .ModalCont .modalInner .iconW {
  width: 5vw;
  height: 5vw;
  min-height: 5vw;

  margin: 10px auto;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../../img/login/Warning.png');
}

.checkBoxContainer .ModalCont .modalInner h2 {
  text-align: center;
  width: 100%;
  font-size: 2.5vw;
  margin: 0px auto;
  color: #F12A51;
  font-family: SofiaPro-Bold;
}

.checkBoxContainer .ModalCont .modalInner .textModal {
  width: 80%;

  margin: 30px auto;

  text-align: center;
  color: #222;
  font-family: SofiaPro-regular;
}

.checkBoxContainer .ModalCont .modalInner .textModal a {
  text-align: center;
  color: #F12A51;
  font-family: SofiaPro-regular;
  text-decoration: none;
}

.checkBoxContainer .ModalCont .azul {
  position: absolute;
  top: 5%;
  left: 4.9%;

  height: 90%;
  min-width: 25px;
  width: 10%;

  background-color: #1CF4E9EE;
  box-shadow: -10px 0px 20px #1CF4E9;

  border-radius: 20px;

  z-index: 1005;
}

.checkBoxContainer .ModalCont .rojo {
  position: absolute;
  top: 5%;
  right: 4.9%;

  height: 90%;
  min-width: 25px;
  width: 10%;
  border-radius: 20px;

  background-color: #F12A51;
  box-shadow: 10px 0px 20px #F12A51;

  z-index: 1005;
}

/* ======================================== */
/* ======================================== */
/* ======================================== */


@media screen and (max-width: 1024px) {
  .checkBoxContainer .textCont {
    font-size: 1.6vw;
    font-weight: 400;
    text-decoration: none;
    color: #FFF;
    text-align: start;
  }

  .checkBoxContainer {
    width: 90%;
    margin: 5px auto;
  }

  .checkBoxContainer .customCheckBox {
    font-size: 14px;
  }

  .checkBoxContainer .ModalCont .close {
    position: absolute;
    top: 12%;
    right: 8%;
  }

  .checkBoxContainer .ModalCont .modalstyle {
    width: 85%;
    height: 75%;
  }

  .checkBoxContainer .ModalCont .modalInner h2 {
    font-size: 5.5vw;
  }

  .checkBoxContainer .ModalCont .modalInner .textModal {
    font-size: 2.3vw;
    width: 95%;
  }
}

@media screen and (max-width: 640px) {
  .checkBoxContainer .textCont {
    font-size: 2.6vw;
    font-weight: 400;
    text-decoration: none;
    color: #FFF;
    text-align: start;
  }

  .checkBoxContainer .ModalCont .modalstyle {
    width: 95%;
    height: 75%;
  }

  .checkBoxContainer .ModalCont .modalInner h2 {
    font-size: 6.5vw;
  }

  .checkBoxContainer .ModalCont .modalInner .textModal {
    font-size: 3vw;
  }
}
